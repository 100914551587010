// dropdown
.dropdown-toggle {
  &:after {
    display: none;
  }
}

/* aggrid */

.list-user-action {
  a {
    i {
      color: #0000006f;
    }
  }
}

.form-group {
  margin-bottom: 1rem;
}
.btn {
  &+.btn {
      margin-left: 10px;
  }
}

.ag-header-viewport, .ag-pinned-left-header, .ag-pinned-right-header {
  background-color: #25476a;
  .ag-cell-label-container {
    color: #fff;
    .ag-header-icon {
      color: #fff;
    }
  }
}

.ag-center-cols-viewport .ag-center-cols-container {
  margin-right: 0 !important;
}

.ag-body-viewport .ag-pinned-right-cols-container {
  margin-right: 0 !important;
}

.ng-select.ng-select-disabled > .ng-select-container{
  background-color: #eff2f7 !important;
  opacity: 1 !important;
}

.ng-select > .ng-select-container {
  min-height: calc(1.5em + 0.5rem + 0px);
}

.ng-select.ng-select-single > .ng-select-container {
  height: calc(1.5em + 0.5rem + 0px);
}

.ng-select.ng-select-multiple .ng-select-container {
  height: calc(1.5em + 0.5rem + 0px);
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: 3px;
}

.ng-select.is-invalid .ng-select-container {
  border-color: #f46a6a !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
  background-color: #556ee6;
}

.ng-select.ng-select-multiple.ng-select-disabled > .ng-select-container .ng-value-container .ng-value {
  background-color: #556ee6;
}


// .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
//     display: inline-block !important;
// }

// .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right {
//   border-left: 0;
// }

// .ng-dropdown-panel { width: auto !important; min-width: 100%; }

.ng-select.search-component.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: 3px;
}

.ng-dropdown-panel.search-component {
  display: inline-block; /* Let content determine the width */
  width: auto !important; /* Allow the width to adjust dynamically */
  min-width: 200px !important; /* Optional: Set a minimum width */
  max-width: 100%; /* Optional: Set a maximum width */
  border: 1px solid #ccc !important;

  .ng-dropdown-panel-items .ng-option {
    padding: 5px 8px;
  }

}

.ng-dropdown-panel.search-component .scrollable-content {
  display: contents !important;

  .ng-option {
      border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
}

.total-padding {
  height: 0 !important;
}