//
// _card.scss
//

.card {
  margin-bottom: $grid-gutter-width;
  box-shadow: $box-shadow;
  margin-bottom: 15px;
  .card-header {
    padding-left: 15px;
    padding-top: 7px;
    letter-spacing: 1px;
    font-size: 14px;
    color: #25476a;
  }
  .card-body {
    padding: 12px;
    .card {
      .card-header {
        padding-left: 0px;
        letter-spacing: normal;
        font-size: 14px;
      }
      .card-body {
        padding-left: 0px;
        padding-right: 0px;
      }
    }
  }
}

.card-drop {
  color: $body-color;
}

.card-title {
  font-size: 15px;
  margin: 0 0 7px 0;
  font-weight: $font-weight-semibold;
}

.card-title-desc {
  color: $card-title-desc;
  margin-bottom: 24px;
}
