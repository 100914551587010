//
// Forms.scss
//

[type='tel'],
[type='url'],
[type='email'],
[type='number'] {
  &::placeholder {
    text-align: left /*rtl: right*/;
  }
}

.form-check {
  position: relative;
  text-align: left /*rtl: right*/;
}

// checkbox input right

.form-check-right {
  padding-left: 0;
  display: inline-block;
  padding-right: $form-check-padding-start;
  .form-check-input {
    float: right;
    margin-left: 0;
    margin-right: $form-check-padding-start * -1;
  }

  .form-check-label {
    display: block;
  }
}

// checkbox

.form-checkbox-outline {
  .form-check-input {
    border-width: 2px;
    background-color: $card-bg;

    &:active {
      filter: none;
    }

    &:checked {
      background-color: $card-bg !important;

      &[type='checkbox'] {
        background-image: none;
      }

      &:after {
        position: absolute;
        content: '\F012C';
        font-family: 'Material Design Icons';
        top: -4px !important;
        left: 1px; /*rtl: -4px */
        font-size: 16px;
        color: $dark;
      }
    }
  }
}

// radio

.form-radio-outline {
  .form-check-input {
    background-color: $card-bg;
    position: relative;

    &:active {
      filter: none;
    }

    &:checked {
      background-color: $card-bg !important;

      &[type='checkbox'] {
        background-image: none;
      }

      &:after {
        position: absolute;
        content: '';
        top: 3px !important;
        left: 3px;
        width: 5px;
        height: 5px;
        border-radius: 50%;
      }
    }
  }
}

// checkbox color

@each $color, $value in $theme-colors {
  .form-check-#{$color} {
    .form-check-input {
      &:checked {
        background-color: $value;
        border-color: $value;
      }
    }
  }

  .form-radio-#{$color} {
    .form-check-input {
      &:checked {
        border-color: $value;
        background-color: $value;
        &:after {
          background-color: $value;
        }
      }
    }
  }
}

.form-check-label {
  cursor: pointer;
  margin-bottom: 0;
}

// Switch sizes

.form-switch-md {
  padding-left: 2.5rem;
  min-height: 24px;
  line-height: 24px;
  .form-check-input {
    width: 40px;
    height: 20px;
    left: -0.5rem;
    position: relative;
  }

  .form-check-label {
    vertical-align: middle;
  }
}

.form-switch-lg {
  padding-left: 2.75rem;
  min-height: 28px;
  line-height: 28px;
  .form-check-input {
    width: 48px;
    height: 24px;
    left: -0.75rem;
    position: relative;
  }
}

.input-group-text {
  margin-bottom: 0px;
}

.form-select {
  padding-right: 1.75rem;
}

label {
  font-weight: 400;
}

.form-group {
  position: relative;
  .form-label {
    position: absolute;
    font-size: 1rem;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: white;
    color: gray;
    padding: 0 0.3rem;
    margin: 0 0.5rem;
    transition: 0.1s ease-out;
    transform-origin: left top;
    pointer-events: none;
    font-size: 0.8125rem;
  }
  input {
    font-size: 1rem;
    outline: none;
    border: 1px solid gray;
    border-radius: 5px;
    padding: 1rem 0.7rem;
    color: gray;
    transition: 0.1s ease-out;
  }
  input:focus {
    border-color: #6200ee;
  }
  input.is-invalid + .form-label {
    transform: translateY(-99%);
  }
  input:focus + .form-label {
    color: #6200ee;
    top: 0;
    font-size: 0.8125rem;
    transform: translateY(-50%) scale(0.9);
  }
  input:not(:placeholder-shown) + .form-label {
    top: 0;
    transform: translateY(-50%) scale(0.9);
    font-size: 0.8125rem;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 9999s ease-in-out 0s;
}

select {
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
}

select:focus {
  background-image: linear-gradient(45deg, #ccc 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, #ccc 50%);
  background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em,
    calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  border-color: green;
  outline: 0;
}
