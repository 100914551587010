/* Importing Bootstrap SCSS file. */
@use 'bootstrap/scss/bootstrap';
@import 'ngx-toastr/toastr';
@import './global-styles/variable';
// @import './global-styles/table';
@import './global-styles/form-style';
@import './global-styles/responsive';

/* You can add global styles to this file, and also import other style files */
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.modal-holder {
  animation-name: example;
  animation-duration: 0.3s;
}

*::placeholder {
  color: #a3a5a7 !important;
  opacity: 1;
}

@keyframes example {
  0% {
    transform: scale(0.5)
  }

  100% {
    transform: scale(1)
  }
}

.carousel-item {
  transition: opacity 0.7s ease !important;
  position: absolute !important;
  display: block !important;
  opacity: 0;
}

.carousel-item.active {
  position: relative !important;
  opacity: 1;
}

.ag-row {
  z-index: 0 !important;

  &.ag-row-focus {
    z-index: 1 !important;
  }
}

/* Importing Bootstrap SCSS file. */
// @import 'bootstrap/scss/bootstrap';
@import "ag-grid-community/styles/ag-grid.css";
@import "ag-grid-community/styles/ag-theme-balham.css";
@import "ag-grid-community/styles/ag-theme-alpine.css";
@import "ag-grid-community/styles/ag-theme-material.css";
@import '~@ng-select/ng-select/themes/default.theme.css';

.ag-center-cols-viewport
.ag-cell {
  border-right: var(--ag-row-border-style) var(--ag-row-border-color) var(--ag-row-border-width);
}

.group-label-sm {
  line-height: 0.5 !important;
}


.actions-button-cell {
  overflow: visible;
}

.ag-icon-filter::before {
  color: white !important;
}

.right-bar-40 {
  width: 40%;
  right: -40%;
  height: 100vh;
  overflow-y: scroll;
}

.right-bar-50 {
  width: 50%;
  right: -50%;
  height: 100vh;
  overflow-y: scroll;
}

.right-bar-25 {
  width: 25%;
  right: -25%;
  transition: width 0.3s ease, right 0.3s ease;
}

.toast-container{
  position: fixed !important;
}

hr {
  margin: 0.4rem 0 !important;
}

//Tabs
.tab-container {
  width: 100%;
}

.tab-nav {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.tab {
  cursor: pointer;
  padding: 10px 15px;
  border: 1px solid #ccc;
  background-color: #f2f2f2;
  margin-right: 5px;
}

.tab.active {
  border-bottom: 2px solid #3853da;
  text-decoration: none;
}

.tab-content {
  // border: 1px solid #ccc;
  padding: 10px;
}


//Prompt Box
.confirmation-dialog-container {
  display: none;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.confirmation-dialog-container.show {
  display: flex;
  align-items: center;
  justify-content: center;
}

.confirmation-dialog {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.confirmation-dialog h2 {
  margin-bottom: 10px;
}

.confirmation-dialog p {
  margin-bottom: 20px;
}

.confirmation-dialog button {
  margin-right: 10px;
}


//Login Container
.login-container {
  margin-left: 0;
  padding: 0;

  .sign-in-page {
    height: 100vh;
    position: relative;
    //  background: url('../assets/images/login/login.jpg') no-repeat scroll 0 0;
    @extend .pink-gradiant-bg;
    background-size: cover;
  }

  #sign-in-page-box {
    background: #fff;
    border-radius: 10px;
    position: relative;
    width: 100%;
    min-height: 480px;
    height: 93vh;
    margin: 15px auto;
    box-shadow: 0px 4px 20px 0px rgba(44, 101, 144, 0.1);
    -webkit-box-shadow: 0px 4px 20px 0px rgba(44, 101, 144, 0.1);
  }

  .sign-in-detail {
    color: var(--iq-white);
  }

  .sign-in-page .height-self-center {
    height: 100vh;
    border-radius: 15px;
  }

  .sign-in-page-data {
    border-radius: 15px;
  }

  .sign-in-detail {
    padding: 50px 80px;
  }

  .sign-in-logo {
    display: inline-block;
    width: 100%;
  }

  .sign-in-logo img {
    height: 50px;
  }

  .sign-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .iq-social-media {
    margin: 0;
    padding: 0;
    float: right;
  }

  .iq-social-media li {
    list-style: none;
    float: left;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }

    a {
      height: 30px;
      width: 30px;
      text-align: center;
      font-size: 18px;
      line-height: 30px;
      display: inline-block;
      -webkit-border-radius: 7px;
      -moz-border-radius: 7px;
      border-radius: 7px;
      background: var(--iq-light-primary);
      color: var(--iq-primary) !important;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .sign-in-page .btn {
    padding: 10px 35px;
    border-radius: 5px;
  }

  .sign-user_card {
    position: relative;
    background: #fff;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    padding: 25px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
      0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
      0 6px 20px 0 rgba(0, 0, 0, 0.19);
    display: block;
    margin: 0 auto;
  }

  .sign-user_logo {
    position: absolute;
    top: -56px;
    border-radius: 50%;
    padding: 10px;
    text-align: center;

    img {
      height: 100px;
      width: 100px;
      border-radius: 50%;
      border: 2px solid white;
    }
  }

  .sign-in-page .form-control,
  .m-profile .form-control {
    color: var(--iq-white);
  }

  .sign-in-page .form-control:focus,
  .m-profile .form-control:focus {
    background: transparent;
    box-shadow: none;
    border: 1px solid var(--iq-primary);
  }

  .invalid-feedback {
    font-size: 100%;
    margin-top: 0px;
  }
}

//login-container close
.pageBox .nav-link {
  padding: 0.5rem 0.5rem;
}

.card {
  background: inherit;
  box-shadow: 0px 0px 4px -2px var(--on-background);
}

.wrapper {
  background: var(--background);
  color: var(--on-background);
}

.switch-color {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 11px;
  margin-bottom: 0;
  top: 1px;
  margin: 0 15px;

  input {
    display: none;
  }
}

.color-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-transition: 0.4s;
  transition: 0.4s;

  &:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: -2px;
    bottom: -3px;
    background-color: #f44336;
    transition: 0.4s;
  }
}

input:checked + .color-slider {
  background-color: #fda19a;
}

input:focus + .color-slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .color-slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */

.color-slider {
  &.round {
    border-radius: 34px;

    &:before {
      border-radius: 50%;
    }
  }
}

.color-settings {
  position: absolute;
  left: auto;
  right: 22%;
  top: 24px;
  z-index: 99;
}

a,
label,
p,
li,
div,
span,
text,
select,
b,
strong,
.table {
  color: inherit;
}

a,
label,
p,
li,
div,
span,
text,
select,
b,
strong,
.table {
  color: inherit;
}

button.action-icon.del {
  position: absolute;
  top: 36px;
  right: 15px;
  border: 1px solid #ddd;
  background-color: $iq-primary;
  color: $iq-white;
}

.items-list {
  width: 120px;

  span.ng-value-label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100px;
    // line-height: 13px;
    // margin-top: -3px
  }
}

.items-list2 {
  width: 75px;

  span.ng-value-label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 56px;
    //line-height: 9px;
  }
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-label {
  display: block;
  padding: 1px 5px;
  float: left;
  // padding-top: 10px;
}

.ng-value.more-list {
  margin-top: 0px;
  line-height: 21px;
  padding-bottom: 3px;
}

.see-password {
  input.form-control {
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
    padding-right: 50px;
  }

  .input-group-addon {
    margin-left: -41px;
    height: 36px;
    z-index: 999;

    a {
      color: $iq-dark;
      padding-top: 10px !important;
      font-weight: bold;
    }
  }
}

@media (min-width: 1100px) and (max-width: 1500px) {
  .items-list2 {
    width: 55px;
  }

  .items-list {
    width: 110px;
  }

  .items-list2 span.ng-value-label {
    width: 37px;
  }

  .items-list span.ng-value-label {
    width: 88px;
  }
}

@media (min-width: 992px) and (max-width: 1024px) {
  .items-list2 {
    width: 50px;
  }

  .items-list2 span.ng-value-label {
    width: 30px;
  }
}

.close-details {
  background-color: #37383a;
  text-align: right;
  border-radius: 4px;

  .delete-btn {
    color: $iq-white;
    font-size: 25px;
    padding: 0;

    &:hover {
      color: $iq-white;
    }
  }
}

.nav-pills .nav-link {
  border-radius: 0;
}

//tooltip for bottom
.tooltip {
  position: relative;
  display: inline-block;
  z-index: 99 !important;
  opacity: 1;
  background: none;

  &:hover .tooltiptext {
    visibility: visible;
  }

  .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: $iq-primary;
    color: $iq-white;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 50%;
    margin-left: -62px;
    font-size: 14px;

    &::after {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent $iq-primary transparent;
    }
  }
}

// tool tip for right
.tooltip-right {
  cursor: pointer;

  .tooltipRtext {
    visibility: hidden;
    width: 120px;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    background: $iq-primary;
    color: $iq-white;
    z-index: 1;
    top: -5px;
    left: 110%;
    font-family: inherit;
    font-size: 14px;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 100%;
      margin-top: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent $iq-primary transparent transparent;
      left: auto;
    }
  }

  &:hover .tooltipRtext {
    visibility: visible;
  }
}

// tootip for left
.tooltip-left {
  .tooltipLtext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: -5px;
    right: 110%;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 100%;
      margin-top: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent transparent black;
    }
  }

  &:hover .tooltipLtext {
    visibility: visible;
  }
}

.info-alert {
  padding: 0.05rem 1rem;
  line-height: 31px;

  i {
    margin-right: 10px;
    font-size: 20px;
    padding-top: 6px;
  }
}

.dark .black-icon {
  display: none;
}

.light .white-icon {
  display: none;
}

.iq-error.position-relative {
  box-shadow: 0px 0px 8px 2px #ddd;
  padding: 15px;
  padding-top: 50px;
  padding-bottom: 50px;
  max-width: 650px;
  border-radius: 10px;
  background-color: #fff;
}

.errorMsg {
  color: #ff3333;
  font-size: 14px;
  font-weight: 500;
}

.mandatory {
  color: #ff3333;
  font-size: 13px;
  font-weight: 500;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::placeholder {
  color: #bd4343;
}

.heading-top {
  padding: 15px 10px 3px 10px;
  margin-bottom: 15px;
  border-radius: 10px;
  margin: 0 15px;
  width: 97.5%;
}

.angular-editor .angular-editor-wrapper .angular-editor-textarea {
  background-color: #fff !important;
}

// .content-page, body.sidebar-main .content-page .container,
body.sidebar-main .iq-sidebar {
  transition: 0.6s ease-in-out;
}

.role-mgmt {
  .table th:nth-child(1),
  .table td:nth-child(1) {
    width: 155px;
  }
}

.col-form-label {
  line-height: 0.5 !important;
}

.form-label {
  margin-bottom: 1px !important;
}

.ml-8 {
  margin-left: 8px !important;
}

// AWB 
.fab-container {
  z-index: 100;
  position: fixed;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.fab-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  background-color: #6571ff;
  color: white;
  border-radius: 50%;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.fab-wrapper:hover {
  background-color: #485ec4;
  transform: scale(1.1);
}

.btn-only-icon {
  font-size: 0;
  padding-left: 22px !important;
  min-height: 27px;
}

// .w-xs {
//   min-width: 20px;
// }

.multiselect-dropdown .dropdown-btn {
    display: inline-table !important;
    padding: 3px 4px !important;
}

.multiselect-dropdown .dropdown-btn .selected-item-container {
    margin: 1px 4px 0 0 !important;
}

.multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
    margin-bottom: 1px !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
    flex-wrap: nowrap !important;
}
