//
// authentication.scss
//

// authentication home icon
.home-btn {
  position: absolute;
  top: 15px;
  right: 25px;
}

// auth 2

.auth-logo {
  .auth-logo-dark {
    height: 70px;
  }
  .auth-logo-light {
    display: $display-none;
  }
}

.auth-body-bg {
  background-color: $card-bg;
}

// auth-pass-inputgroup

.auth-pass-inputgroup {
  input[type='input'] + .btn .mdi-eye-outline {
    &:before {
      content: '\F06D1';
    }
  }
}

// authentication full page

.auth-full-bg {
  // background-color: rgba($primary, 0.25);

  display: flex;

  @media (min-width: 1200px) {
    height: 100vh;
  }

  &::before {
    content: '';
    position: absolute;
    width: 300px;
    height: 300px;
    border-radius: 50%;
  }

  .bg-overlay {
    background: url('../../../images/bg-auth-overlay.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.auth-full-page-content {
  display: flex;
  background-color: $sidebar-dark-bg;
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 9999s ease-in-out 0s;
    -webkit-text-fill-color: #ffffff;
  }
  select {
    border: 1px solid rgba(255, 255, 255, 0.5019607843);
  }
  @media (min-width: 1200px) {
    min-height: 100vh;
  }
  .form-group {
    .form-icon {
      right: 0px;
      border: 1px solid #ffffff80;
      height: 36px;
    }
  }
  .form-group input {
    border: 1px solid #ffffff80;
    color: #ffffff;
    background: transparent;
    &:focus {
      border: 1px solid #ffffff80;
      background: $sidebar-dark-bg;
    }
  }
  .form-group input:focus + .form-label {
    background: $sidebar-dark-bg;
    color: #ffffff80;
  }
  .form-group label {
    background-color: transparent;
    color: #ffffff80;
  }
  .form-group input:not(:placeholder-shown) + .form-label {
    background-color: $sidebar-dark-bg;
    color: #ffffff80;
  }
}

.auth-review-carousel {
  &.owl-theme {
    .owl-dots {
      .owl-dot {
        span {
          background-color: rgba($primary, 0.25);
        }
        &.active,
        &:hover {
          span {
            background-color: $primary;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .auth-full-bg {
    display: none;
  }
  .auth-full-page-content {
    height: 100vh;
  }
}
