.iq-comingsoon-form {
  position: relative;
  button {
    position: absolute;
    right: 0;
    top: 0;
    padding: 11px 30px;
  }
}
.form-control {
  &.comming {
    background-color: $iq-light-card;
  }
}

.iq-progress-bar {
  background: $iq-light;
  box-shadow: 0 0 0;
  height: 6px;
  margin: 0;
  position: relative;
  width: 100%;
  border-radius: 5px;
  & > span {
    background: $iq-primary none repeat scroll 0 0;
    display: block;
    height: 100%;
    width: 0;
    border-radius: 5px;
  }
}

.iq-card-header {
  .dropdown-bg {
    background: $iq-light-primary;
    padding: 8px 15px;
    color: $iq-primary;
    border-radius: 5px;
  }
  .dropdown-menu {
    margin: 0;
    padding: 0;
    font-size: 13px;
    top: 20px !important;
    border-radius: 5px;
    border-color: rgba(210, 201, 255, 0.4);
    -webkit-box-shadow: 0px 0px 20px 0px rgba(44, 101, 144, 0.1);
    -moz-box-shadow: 0px 0px 20px 0px rgba(44, 101, 144, 0.1);
    box-shadow: 0px 0px 20px 0px rgba(44, 101, 144, 0.1);
    background-color: $iq-body-bg;
    .dropdown-item {
      padding: 8px 15px;
      color: $iq-body-text;
      i {
        font-size: 14px;
        vertical-align: middle;
      }
    }
  }
}
.dropdown-menu .dropdown-item:focus,
.dropdown-menu .dropdown-item:hover {
  background: transparent;
  color: $iq-primary;
}
.iq-icon-box {
  height: 64px;
  width: 64px;
  line-height: 64px;
  text-align: center;
  margin-right: 10px;
  font-size: 26px;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

.form_gallery {
  position: relative;
  width: 100%;
  height: 46px;
  margin-bottom: 20px;
  overflow: hidden;
  -webkit-border-radius: 6px;
  border-radius: 6px;

  label {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    z-index: 2;
    height: 46px;
    color: rgba(255, 255, 255, 0.8);
    padding: 0 50px 0 20px;
    letter-spacing: 0.4px;
    background-color: $iq-body-bg;
    margin: 0;
    width: 100%;
    font-size: 16px;
    cursor: pointer;
    -webkit-transition: 0.4s ease;
    -moz-transition: 0.4s ease;
    transition: 0.4s ease;

    &:before {
      content: '\f302';
      pointer-events: none;
      font-family: 'Line Awesome Free';
      position: absolute;
      right: 20px;
      top: 0;
      line-height: 46px;
      font-size: 18px;
    }
  }

  input {
    position: absolute;
    left: -9999px;
    opacity: 0;
    z-index: 1;
  }
}

.form_input {
  width: 100%;
  background-color: rgba(210, 201, 255, 0.04);
  border: 1px solid transparent;
  height: 46px;
  position: relative;
  color: $iq-white;
  font-size: 16px;
  width: 100%;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  padding: 0 20px;
}

.form_textarea {
  border: 1px solid transparent;
  display: block;
  height: 136px;
  position: relative;
  color: $iq-white;
  font-size: 16px;
  width: 100%;
  padding: 15px 20px;
  letter-spacing: 0.4px;
  resize: none;
  background-color: rgba(210, 201, 255, 0.04);
  -webkit-border-radius: 6px;
  border-radius: 6px;
}

.form_video-upload {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: $iq-body-bg;

  p {
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: 310px;
    color: $iq-white;
  }

  input {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
  }
}

.radio-box label {
  color: $iq-body-text;
}

.radio-btn {
  margin-left: 20px;
}

.select2-container--bootstrap4
  .select2-selection--single
  .select2-selection__arrow
  b {
  z-index: 9;
}

.iq-color-primary {
  color: $iq-dark;
}

.table-primary > {
  td,
  th {
    color: $iq-dark;
  }
}

/* ----------------------------------------------
  Select 2
  ------------------------------------------------ */

.iq-custom-select {
  position: relative;

  .select2-container--bootstrap4 .select2-selection {
    background-color: $iq-body-bg;
    border: none;
    border-radius: 0;
    color: #fff;
  }
}

.select2-dropdown {
  background-color: $iq-body-bg;
  border: none;
  border-radius: 0;
  color: #fff;
  top: 0;
  box-shadow: 0 12px 34px 0 rgba(19, 10, 10, 1);
}

.iq-custom-select select {
  box-shadow: none;
}

.select2-container--bootstrap4 {
  .select2-dropdown .select2-results__option[aria-selected='true'] {
    background-color: $iq-body-bg;
  }

  .select2-results__option--highlighted {
    background-color: $iq-primary;
    color: $iq-white;

    &.select2-results__option[aria-selected='true'] {
      background-color: $iq-primary;
      color: $iq-white;
    }
  }
}

.select2-container .select2-selection--single .select2-selection__rendered {
  line-height: calc(1.5em + 1.2rem);
  padding-left: 10px;
}

.select2-container--bootstrap4.select2-container--focus .select2-selection {
  box-shadow: none;
}

.select2-search--dropdown .select2-search__field {
  border-radius: 0;
  border: none;
  background: $iq-light-card;
  color: $iq-white;
}

.select2-container--bootstrap4 {
  .select2-selection--single .select2-selection__arrow b {
    border: none;

    &:before {
      content: '\ea41';
      position: absolute;
      font-family: 'remixicon';
      top: 50%;
      transform: translateY(-50%);
      right: 1px;
      color: $iq-white;
      font-size: 16px;
      z-index: 99;
      transition: all 0.4s ease;
    }
  }

  &.select2-container--open .select2-selection__arrow b:before {
    transform: translateY(-50%) rotate(180deg);
  }

  .select2-selection--single {
    .select2-selection__arrow {
      b {
        margin-top: 0;
      }

      width: auto;
    }

    height: 45px !important;
  }

  .select2-selection--multiple {
    height: 45px !important;
  }

  .select2-dropdown.select2-dropdown--above {
    border-radius: 0;
    border: none;
  }
}

.sea-epi .select2-container {
  width: 150px !important;
}

/* colors changes in radio button, checkbox, switch */

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}

.custom-checkbox {
  &.custom-checkbox-color input.bg-primary ~ label::before,
  &.custom-checkbox-color-check input:checked.bg-primary ~ label::before {
    background-color: $iq-primary !important;
    border-color: $iq-primary !important;
  }
}

.custom-radio {
  &.custom-radio-color input.bg-primary ~ label::before,
  &.custom-radio-color-checked input:checked.bg-primary ~ label::before {
    background-color: $iq-primary !important;
    border-color: $iq-primary !important;
  }
}

.custom-switch.custom-switch-color input:checked.bg-primary ~ label::before {
  background-color: $iq-primary !important;
  border-color: $iq-primary !important;
}

.custom-checkbox {
  &.custom-checkbox-color input.bg-success ~ label::before,
  &.custom-checkbox-color-check input:checked.bg-success ~ label::before {
    background-color: $iq-success !important;
    border-color: $iq-success !important;
  }
}

.custom-radio {
  &.custom-radio-color input.bg-success ~ label::before,
  &.custom-radio-color-checked input:checked.bg-success ~ label::before {
    background-color: $iq-success !important;
    border-color: $iq-success !important;
  }
}

.custom-switch.custom-switch-color input:checked.bg-success ~ label::before {
  background-color: $iq-success !important;
  border-color: $iq-success !important;
}

.custom-checkbox {
  &.custom-checkbox-color input.bg-danger ~ label::before,
  &.custom-checkbox-color-check input:checked.bg-danger ~ label::before {
    background-color: $iq-danger !important;
    border-color: $iq-danger !important;
  }
}

.custom-radio {
  &.custom-radio-color input.bg-danger ~ label::before,
  &.custom-radio-color-checked input:checked.bg-danger ~ label::before {
    background-color: $iq-danger !important;
    border-color: $iq-danger !important;
  }
}

.custom-switch.custom-switch-color input:checked.bg-danger ~ label::before {
  background-color: $iq-danger !important;
  border-color: $iq-danger !important;
}

.custom-checkbox {
  &.custom-checkbox-color input.bg-warning ~ label::before,
  &.custom-checkbox-color-check input:checked.bg-warning ~ label::before {
    background-color: $iq-warning !important;
    border-color: $iq-warning !important;
  }
}

.custom-radio {
  &.custom-radio-color input.bg-warning ~ label::before,
  &.custom-radio-color-checked input:checked.bg-warning ~ label::before {
    background-color: $iq-warning !important;
    border-color: $iq-warning !important;
  }
}

.custom-switch.custom-switch-color input:checked.bg-warning ~ label::before {
  background-color: $iq-warning !important;
  border-color: $iq-warning !important;
}

.custom-checkbox {
  &.custom-checkbox-color input.bg-dark ~ label::before,
  &.custom-checkbox-color-check input:checked.bg-dark ~ label::before {
    background-color: $iq-body-text !important;
    border-color: $iq-body-text !important;
  }
}

.custom-radio {
  &.custom-radio-color input.bg-dark ~ label::before,
  &.custom-radio-color-checked input:checked.bg-dark ~ label::before {
    background-color: $iq-body-text !important;
    border-color: $iq-body-text !important;
  }
}

.custom-switch.custom-switch-color input:checked.bg-dark ~ label::before {
  background-color: $iq-body-text !important;
  border-color: $iq-body-text !important;
}

.custom-checkbox {
  &.custom-checkbox-color input.bg-info ~ label::before,
  &.custom-checkbox-color-check input:checked.bg-info ~ label::before {
    background-color: $iq-info !important;
    border-color: $iq-info !important;
  }
}

.custom-radio {
  &.custom-radio-color input.bg-info ~ label::before,
  &.custom-radio-color-checked input:checked.bg-info ~ label::before {
    background-color: $iq-info !important;
    border-color: $iq-info !important;
  }
}

.custom-switch.custom-switch-color input:checked.bg-info ~ label::before {
  background-color: $iq-info !important;
  border-color: $iq-info !important;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: transparent;
}

.form-control {
  &.form-control-sm {
    height: 28px;
    & + .btn {
      background-color: #c8d5f5;
      border: 1px solid transparent;
      color: #000;
    }
  }
  &.form-control-lg {
    height: 50px;
    line-height: 50px;
  }
}

textarea {
  resize: none;
}

.form-control {
  &:disabled,
  &[readonly] {
    // background-color: $iq-gray !important;
    opacity: 1;
  }
}

.form-control {
  //background-color: $iq-body-bg;
  border: 1px solid transparent;
  height: 36px;
  position: relative;
  color: $iq-body-bg;
  font-size: 16px;
  width: 100%;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  &:focus {
    color: $iq-dark1;
    border-color: transparent;
    box-shadow: none;
    // background-color: $iq-body-bg;
  }
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  padding-top: 0px;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-placeholder {
  top: 3px;
}

.form-group,
.dataTables_filter {
  input[type='number'],
  input[type='search'],
  input[type='text'] {
    color: $iq-dark;
  }
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value {
  background-color: #c7c7c7;
}

.custom-files {
  background-color: #ff3366;
  text-align: center;

  a.action-icon.add {
    font-size: 20px;
  }
}

.custom-file-input {
  position: absolute;
  z-index: 2;
  width: 95%;
  height: calc(2.5em + 0rem + 0px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
  left: 5px;
}

// for upload image style

.upload-section {
  position: relative;
  .action-icon {
    position: absolute;
    bottom: 6px;
    color: $iq-white;
    font-size: 20px;
    &.add {
      left: 15px;
    }
    &.del {
      left: 49px;
    }
  }
}
.custom-files.verticalCustom {
  max-width: 142px;
}
.vertical-block {
  max-width: 143px;
  height: 212px;
  background-color: $iq-white;
  text-align: center;
  border: 1px solid $iq-gray;
  // display: flex;
  align-items: center;
  button.action-icon.del {
    right: auto;
    left: 132px;
  }
  .custom-files.verticalCustom {
    align-items: center;
    justify-content: center;
    border-top: 1px solid #37383a;
    margin-top: -37px;
    width: 100%;
  }
  img {
    height: 86%;
    width: 100%;
    margin-bottom: 36px;
    max-height: 183px;
    max-width: 100%;
  }
  .custom-file-input {
    max-width: 152px;
  }
}

.horizontal-block {
  height: 213px;
  background-color: $iq-white;
  text-align: center;
  border: 1px solid $iq-gray;
  //   display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;

  .custom-files.horizontalCustom {
    align-items: center;
    justify-content: center;
    border-top: 1px solid #37383a;
    margin-top: -37px;
    width: 100%;
  }
  img {
    max-height: 211px;
    max-width: 100%;
    height: 86%;
    width: 100%;
    margin-bottom: 36px;
  }
}
.custom-files label,
.custom-files a {
  color: $iq-white;
  margin-bottom: 0;
}
.top-form {
  margin: 0;
  margin-bottom: 10px;
  border-radius: 5px;
  padding-top: 10px;
  label {
    font-weight: bold;
  }
}

.ng-select .ng-clear-wrapper {
  display: flex;
}

.dropdown-item {
  background-color: #ffffff !important;
}

.dropdown-item.active {
  background-color: #e9e9e9 !important;
  color: #25476a !important;
  font-weight: 700;
}